<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('update:is-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">{{ Object.entries(data).length === 0 ? $t("Add New") : $t("Update") }}</h5>

        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
      </div>
      <!-- Form -->
      <validation-observer ref="formData">
        <b-form class="p-2">
          <b-row>
            <b-col cols="12">
              <b-form-group :label="$t('Image')" label-for="register-image">
                <!-- media -->
                <b-media no-body>
                  <b-media-aside>
                    <b-link>
                      <b-img v-if="image" ref="previewEl" rounded :src="image" height="80" />
                      <b-img
                        v-else
                        ref="previewEl"
                        :src="require('@/assets/images/blank/no_image.png')"
                        rounded
                        height="80"
                      />
                    </b-link>
                    <!--/ avatar -->
                  </b-media-aside>

                  <b-media-body class="mt-75 ml-75">
                    <!-- upload button -->
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      size="sm"
                      class="mb-75 mr-75"
                      @click="$refs.refInputEl.$el.click()"
                    >{{ $t("Upload") }}</b-button>
                    <b-form-file
                      ref="refInputEl"
                      accept=".webp, .jpeg, .jpg, .png, .gif"
                      :hidden="true"
                      plain
                      @change="inputImageRenderer"
                    />
                    <!--/ upload button -->

                    <!-- reset -->
                    <b-button
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      variant="outline-secondary"
                      size="sm"
                      class="mb-75 mr-75"
                      @click="resetImage"
                    >{{ $t("Reset") }}</b-button>
                    <!--/ reset -->
                    <b-card-text>
                      {{ $t('Allowed') }} JPG, GIF {{ $t('or') }} PNG. {{ $t('Max size of') }}
                      300KB
                    </b-card-text>
                    <b-card-text>
                      {{ $t('Supported resolutions') }} 640×480, 800×600, 960×720, 1024×768, 1280×960, 1400×1050, 1440×1080, 1600×1200, 1856×1392, 1920×1440,
                    </b-card-text>
                  </b-media-body>
                </b-media>
                <!--/ media -->
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group :label="$t('Code')" label-for="code">
                <validation-provider #default="{ errors }" name="Code" vid="code" rules="required">
                  <b-form-input
                    id="code"
                    v-model="code"
                    name="code"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('Code')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group :label="$t('Name')" label-for="register-name">
                <b-row>
                  <b-col v-for="(item, index) in language" :key="`${index}_name`" cols="12">
                    <!-- firstName -->
                    <!-- firstName -->
                    <b-form-group :label="$t(item.name)" :label-for="item.name">
                      <validation-provider #default="{ errors }" :name="item.name" :vid="item.name">
                        <b-form-input
                          v-if="name[index]"
                          :id="item.name"
                          :name="item.name"
                          :value="name[index]['value']"
                          :state="errors.length > 0 ? false : null"
                          :placeholder="$t(item.name)"
                          @input="inputLanguage($event, item.lang, index, 'name')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group :label="$t('Description')" label-for="description">
                <b-row>
                  <b-col v-for="(item, index) in language" :key="`${index}_description`" cols="12">
                    <b-form-group :label="$t(item.name)" :label-for="item.name">
                      <validation-provider #default="{ errors }" :name="item.name" :vid="item.name">
                        <b-form-input
                          v-if="description[index]"
                          :id="item.name"
                          :name="item.name"
                          :value="description[index]['value']"
                          :state="errors.length > 0 ? false : null"
                          :placeholder="$t(item.name)"
                          @input="inputLanguage($event, item.lang, index, 'description')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group :label="$t('Minimum Payment')" label-for="minimumPayment">
                <validation-provider
                  #default="{ errors }"
                  name="minimumPayment"
                  vid="minimumPayment"
                  rules="required"
                >
                  <cleave
                    id="minimumPayment"
                    v-model="minimumPayment"
                    class="form-control"
                    :raw="false"
                    :options="optionCleave.number"
                    :placeholder="$t('Minimum Payment')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group :label="$t('Max Discount')" label-for="maxDiscount">
                <validation-provider
                  #default="{ errors }"
                  name="maxDiscount"
                  vid="maxDiscount"
                  rules="required"
                >
                  <cleave
                    id="maxDiscount"
                    v-model="maxDiscount"
                    class="form-control"
                    :raw="false"
                    :options="optionCleave.number"
                    :placeholder="$t('Max Discount')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group :label="$t('Amount')" label-for="amount">
                <validation-provider
                  #default="{ errors }"
                  name="Amount"
                  vid="amount"
                  rules="required"
                >
                  <cleave
                    id="amount"
                    v-model="amount"
                    class="form-control"
                    :raw="false"
                    :options="optionCleave.number"
                    :placeholder="$t('Amount')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group :label="$t('Calculation Method')" label-for="calculationMethod">
                <validation-provider
                  #default="{ errors }"
                  name="CalculationMethod"
                  vid="calculationMethod"
                  rules="required"
                >
                  <v-select
                    id="calculationMethod"
                    v-model="calculationMethod"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="name"
                    :options="OptionCal"
                    :clearable="false"
                    :reduce="(OptionCal) => OptionCal.value"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group :label="$t('Release Date')" label-for="releaseDate">
                <validation-provider
                  #default="{ errors }"
                  name="ReleaseDate"
                  vid="releaseDate"
                  rules="required"
                >
                  <flat-pickr id="releaseDate" v-model="releaseDate" class="form-control" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group :label="$t('Start Date')" label-for="startDate">
                <validation-provider
                  #default="{ errors }"
                  name="StartDate"
                  vid="startDate"
                  rules="required"
                >
                  <flat-pickr id="startDate" v-model="startDate" class="form-control" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group :label="$t('ExpireDate')" label-for="expireDate">
                <validation-provider
                  #default="{ errors }"
                  name="ExpireDate"
                  vid="expireDate"
                  rules="required"
                >
                  <flat-pickr id="expireDate" v-model="expireDate" class="form-control" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
            >
              <b-form-group
                :label="$t('Category')"
                label-for="register-category"
              >
                <validation-provider
                  #default="{ errors }"
                  name="category"
                  vid="category"
                  rules=""
                >
                  <v-select
                    id="register-category"
                    v-model="categoryId"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="name"
                    :options="respDataCategory"
                    :reduce="(respDataCategory) => respDataCategory._id"
                    class="select-size-lg"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group :label="$t('Limit')" label-for="limit">
                <validation-provider
                  #default="{ errors }"
                  name="Limit"
                  vid="limit"
                  rules="required"
                >
                  <cleave
                    id="limit"
                    v-model="limit"
                    class="form-control"
                    :raw="false"
                    :options="optionCleave.number"
                    :placeholder="$t('Limit')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group label-for="newUser">
                <b-form-checkbox
                  id="newUser"
                  v-model="newUser"
                  name="checkbox-1"
                >{{ $t("New User") }} ({{ $t('New users only') }})</b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-row>
          <div class="d-flex mt-2">
            <b-button variant="primary" class="mr-2" @click="validationForm()">{{ $t('Submit') }}</b-button>
            <b-button type="button" variant="outline-secondary" @click="hide">{{ $t('Cancel') }}</b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BSidebar,
  BButton,
  BFormFile,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BImg,
  BCardText,
  BInputGroupAppend,
  BInputGroup,
  BFormInput,
  BForm,
  BFormGroup,
  BRow,
  BCol,
  BFormCheckbox
} from "bootstrap-vue";
import { required } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
import store from "@/store";
import Cleave from "vue-cleave-component";

export default {
  components: {
    BSidebar,
    BButton,
    BFormFile,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BImg,
    BCardText,
    BForm,
    // eslint-disable-next-line vue/no-unused-components
    BFormInput,
    flatPickr,
    BFormGroup,
    // eslint-disable-next-line vue/no-unused-components
    BInputGroupAppend,
    // eslint-disable-next-line vue/no-unused-components
    BInputGroup,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BFormCheckbox,
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
    Cleave
  },
  directives: {
    Ripple
  },
  mixins: [togglePasswordVisibility],
  model: {
    prop: "isSidebarActive",
    event: "update:is-sidebar-active"
  },
  props: {
    storeModuleName: {
      type: String,
      required: true
    },
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      default: () => {}
    },
    show: {
      type: Boolean,
      required: false
    }
  },
  data() {
    return {
      optionCleave: {
        number: {
          numeral: true
        }
      },
      dataId: null,
      image: "",
      code: "",
      name: [],
      description: [],
      minimumPayment: "0",
      maxDiscount: "0",
      amount: "0",
      calculationMethod: "constant",
      categoryId: "",
      releaseDate: new Date(),
      startDate: new Date(),
      expireDate: new Date(),
      limit: "",
      newUser: false,
      required
    };
  },
  computed: {
    respDataCategory() {
      return store.state["couponCategory"].respData != null
        ? store.state["couponCategory"].respData.data.map(e => {
          const indexLang = e.name.findIndex(name => name.lang === this.$i18n.locale)
          if (indexLang > -1) {
            return {
              _id: e._id,
              name: e.name[indexLang].value,
            }
          }
          return {
            _id: e._id,
            name: e.name[0].value,
          }
        })
        : [];
    },
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    OptionCal() {
      return [
        { name: this.$t("Percent"), value: "percent" },
        { name: this.$t("Constant"), value: "constant" }
      ];
    },
    language() {
      return store.state.appConfig.systemConfig
        ? store.state.appConfig.systemConfig.language
        : [];
    }
  },
  watch: {
    isSidebarActive(val) {
      if (val) {
        this.getCategory()
        if (Object.entries(this.data).length === 0) {
          this.$refs.formData.reset();
          this.initValues();
          if (this.name.length !== this.language.length) {
            for (let index = 0; index < this.language.length; index += 1) {
              const indexLanguage = this.name.findIndex(
                element => element.lang === this.language[index].lang
              );
              console.log(indexLanguage);

              if (indexLanguage < 0) {
                this.name.push({
                  lang: this.language[index].lang, // th , en
                  value: ""
                });
              }
            }
          }
          if (this.description.length !== this.language.length) {
            for (let index = 0; index < this.language.length; index += 1) {
              const indexLanguage = this.description.findIndex(
                element => element.lang === this.language[index].lang
              );
              console.log(indexLanguage);

              if (indexLanguage < 0) {
                this.description.push({
                  lang: this.language[index].lang, // th , en
                  value: ""
                });
              }
            }
          }
        } else {
          const {
            _id,
            image,
            code,
            name,
            description,
            minimumPayment,
            maxDiscount,
            amount,
            calculationMethod,
            releaseDate,
            startDate,
            expireDate,
            categoryId,
            limit,
            newUser
          } = this.data;
          this.dataId = _id;
          this.image = image;
          this.code = code;
          this.minimumPayment = minimumPayment;
          this.maxDiscount = maxDiscount;
          this.amount = amount;
          this.calculationMethod = calculationMethod;
          this.releaseDate = releaseDate;
          this.startDate = startDate;
          this.expireDate = expireDate;
          this.categoryId = categoryId;
          this.limit = limit;
          this.newUser = newUser;
          this.name = name;
          if (this.name.length !== this.language.length) {
            for (let index = 0; index < this.language.length; index += 1) {
              const indexLanguage = this.name.findIndex(
                element => element.lang === this.language[index].lang
              );
              if (indexLanguage < 0) {
                this.name.push({
                  lang: this.language[index].lang, // th , en
                  value: ""
                });
              }
            }
            for (let index = 0; index < this.name.length; index += 1) {
              const indexLanguage = this.language.findIndex(
                element => element.lang === this.name[index].lang
              );
              if (indexLanguage < 0) {
                this.name.splice(index, 1);
              }
            }
          }
          this.description = description;
          if (this.description.length !== this.language.length) {
            for (let index = 0; index < this.language.length; index += 1) {
              const indexLanguage = this.description.findIndex(
                element => element.lang === this.language[index].lang
              );
              if (indexLanguage < 0) {
                this.description.push({
                  lang: this.language[index].lang, // th , en
                  value: ""
                });
              }
            }
            for (let index = 0; index < this.description.length; index += 1) {
              const indexLanguage = this.language.findIndex(
                element => element.lang === this.description[index].lang
              );
              if (indexLanguage < 0) {
                this.description.splice(index, 1);
              }
            }
          }
        }
      }
    }
  },
  methods: {
    getCategory() {
      const obj = {
      currentPage: 1,
      pageSize: 0,
      searchQuery: ""
    };

      store
        .dispatch(`couponCategory/get`, obj)
        // eslint-disable-next-line no-unused-vars
        .then(result => {

          //
          // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
          // eslint-disable-next-line no-unused-vars
        })
        .catch(error => {
          this.show = false;
          this.$toast({
            component: ToastificationContent,
            position: "bottom-right",
            props: {
              title: this.$t("Error"),
              icon: "ErrorIcon",
              variant: "danger",
              text: this.$t(error.response.data.message)
            }
          });
        });
    },
    inputLanguage(input, lang, index, type) {
      if (type === "name") {
        if (this.name[index].lang === lang) {
          this.name[index].value = input;
        }
      } else if (type === "description") {
        if (this.description[index].lang === lang) {
          this.description[index].value = input;
        }
      }
    },
    inputImageRenderer(input) {
      // eslint-disable-next-line prefer-destructuring
      if (input.target.files && input.target.files[0]) {
        if (input.target.files[0].size > 300000) {
          this.$toast({
            component: ToastificationContent,
            position: "bottom-right",
            props: {
              title: this.$t("Error"),
              icon: "ErrorIcon",
              variant: "danger",
              text: `${this.$t("Allowed")} JPG, GIF ${this.$t(
                "or"
              )} PNG. ${this.$t("Max size of")} 300KB`
            }
          });
          return;
        }

        const reader = new FileReader();
        reader.onload = e => {
          this.image = e.target.result;
          // const obj = { image: e.target.result }
          // this.$store
          //   .dispatch(`${this.storeModuleName}/uploadImage`, obj)
          //   .then(resp => {
          //     console.log(resp)
          //     this.image = resp.data.data.url
          //   })
          //   .catch(err => {
          //     console.error(err)
          //   })
        };
        reader.readAsDataURL(input.target.files[0]);
      }
    },
    resetImage() {
      this.image = "";
    },
    initValues() {
      this.dataId = null;
      this.image = "";
      this.code = "";
      this.name = [];
      this.description = [];
      this.minimumPayment = "0";
      this.maxDiscount = "0";
      this.amount = "0";
      this.calculationMethod = "constant";
      this.releaseDate = new Date();
      this.startDate = new Date();
      this.expireDate = new Date();
      this.limit = "";
      this.categoryId = "";
      this.newUser = false;
    },
    validationForm() {
      this.$refs.formData.validate().then(success => {
        if (success) {
          const obj = {
            id: this.dataId,
            image: this.image,
            code: this.code,
            name: this.name,
            description: this.description,
            minimumPayment: this.minimumPayment.replaceAll(",", ""),
            maxDiscount: this.maxDiscount.replaceAll(",", ""),
            amount: this.amount.replaceAll(",", ""),
            calculationMethod: this.calculationMethod,
            releaseDate: this.releaseDate,
            startDate: this.startDate,
            expireDate: this.expireDate,
            categoryId: this.categoryId,
            limit: this.limit.replaceAll(",", ""),
            newUser: this.newUser
          };
          this.$emit("update:show", true);
          if (this.dataId !== null) {
            store
              .dispatch(`${this.storeModuleName}/update`, obj)
              .then(result => {
                this.$emit("update:is-sidebar-active", false);
                this.$emit("update:show", false);

                // this.showToast('success', 'bottom-right')
              })
              .catch(error => {
                console.log("fetchUsers Error : ", error);
                this.$toast({
                  component: ToastificationContent,
                  position: "bottom-right",
                  props: {
                    title: this.$t("Error"),
                    icon: "ErrorIcon",
                    variant: "danger",
                    text: this.$t(error.response.data.message)
                  }
                });
                this.$emit("update:show", false);
                // this.showToast('danger', 'bottom-right')
              });
          } else {
            delete obj.id;
            store
              .dispatch(`${this.storeModuleName}/add`, obj)
              .then(result => {
                this.$emit("update:is-sidebar-active", false);
                this.$emit("update:show", false);

                // this.showToast('success', 'bottom-right')
              })
              .catch(error => {
                console.log("fetchUsers Error : ", error);
                this.$toast({
                  component: ToastificationContent,
                  position: "bottom-right",
                  props: {
                    title: this.$t("Error"),
                    icon: "ErrorIcon",
                    variant: "danger",
                    text: this.$t(error.response.data.message)
                  }
                });
                this.$emit("update:show", false);
                // this.showToast('danger', 'bottom-right')
              });
          }
        }
      });
    }
  }
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
